<template>
  <div style="padding: 20px">
    <el-form
      :inline="true"
      :model="query"
      ref="batchPageCreate"
      class="demo-form-inline"
    >
      <div class="df">
        <el-form-item prop="keyword">
          <el-input
            v-model="query.keyword"
            placeholder="请输入关键字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="keyword">
          <el-autocomplete
            placeholder="企业名称"
            v-model="query.company_name"
            :fetch-suggestions="querySearchAsync1"
            clearable
            style="width: 70%; float: left"
            @select="handleSelect1"
          >
            <template slot-scope="{ item }">
              <span class="name">{{ item.company_name }}</span>
              <span
                class="addr"
                style="font-size: 12px; color: silver; float: right"
                >{{ item.credit_code }}</span
              >
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-button
          type="primary"
          class="mr10"
          @click="onSubmit"
          style="height: 40px; margin-bottom: 20px"
          >查询</el-button
        >
        <el-button
          type="success"
          class="mr10"
          style="height: 40px; margin-bottom: 20px"
          @click="
            title = '新增';
            dailogVisible = true;
          "
          >添加</el-button
        >
      </div>
    </el-form>
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="chipName" label="芯片名称"></el-table-column>
      <el-table-column prop="chipId" label="芯片id"></el-table-column>
      <el-table-column prop="companyName" label="所属企业"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="openMode(scope.row)"
            >模式管理</el-button
          >
          <el-button type="text" size="small" @click="edit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            class="red"
            @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dailogVisible"
      width="40%"
      @closed="editId = null"
    >
      <div
        style="height: 300px; overflow: hidden; flex-direction: column"
        class="df"
      >
        <div style="flex: 1">
          <el-form :model="form" label-position="right" label-width="120px">
            <el-form-item size="medium" label="设备编号id：">
              <el-input
                placeholder="设备编号id"
                v-model="form.chipId"
                clearable
                style="width: 70%; float: left"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="medium" label="设备名称：">
              <el-input
                placeholder="设备名称"
                v-model="form.chipName"
                clearable
                style="width: 70%; float: left"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="medium" label="企业名称：">
              <el-autocomplete
                placeholder="企业名称"
                v-model="form.company_name"
                :fetch-suggestions="querySearchAsync"
                clearable
                style="width: 70%; float: left"
                @select="handleSelect"
              >
                <template slot-scope="{ item }">
                  <span class="name">{{ item.company_name }}</span>
                  <span
                    class="addr"
                    style="font-size: 12px; color: silver; float: right"
                    >{{ item.credit_code }}</span
                  >
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="df" style="justify-content: flex-end; padding-top: 10px">
        <el-button type="success" style="height: 40px" @click="addChip"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="模式分配" :visible.sync="modeDailogVisible" width="50%">
      <div
        class="df"
        style="
          height: 600px;
          background-color: #f8f8f8;
          padding: 6px;
          border-radius: 20px;
        "
      >
        <div
          class="df"
          style="
            flex-direction: column;
            flex: 1;
            margin-right: 3px;
            background-color: #fff;
            height: 100%;
            border-radius: 14px;
            width: 0;
          "
        >
          <div style="padding: 20px"></div>
          <div
            class="scroll-box"
            style="
              flex: 1;
              height: 0;
              padding: 0 20px;
              box-sizing: border-box;
              overflow-y: scroll;
            "
          >
            <el-table :data="modeList" style="width: 100%">
              <el-table-column
                prop="modeName"
                label="模式名称"
              ></el-table-column>
              <el-table-column prop="" label="分配次数">
                <template slot-scope="scope">
                  <el-form
                    :ref="'form' + scope.$index"
                    :model="scope.row"
                    label-width="0px"
                    :rules="rules"
                  >
                    <el-form-item label="" prop="num">
                      <el-input
                        v-model="scope.row.num"
                        placeholder="请输入分配次数"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="addMode(scope.row, scope.$index)"
                    >添加</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 20px; justify-content: flex-end" class="df">
            <el-pagination
              background
              :page-size="modeQuery.limit"
              layout="total,prev, pager, next"
              :total="modeTotal"
              :current-page="modeQuery.page"
              @current-change="modePageChange"
            >
            </el-pagination>
          </div>
        </div>
        <div
          class="df scroll-box"
          style="
            flex-direction: column;
            flex: 1;
            margin-left: 3px;
            background-color: #fff;
            height: 100%;
            border-radius: 14px;
            width: 0;
            overflow-y: scroll;
          "
        >
          <div style="padding: 20px"></div>
          <div style="padding: 0 20px; flex: 1; height: 0">
            <el-table
              :data="chipModeList"
              style="width: 100%; box-sizing: border-box"
            >
              <el-table-column
                prop="modeName"
                label="模式名称"
              ></el-table-column>
              <el-table-column prop="num" label="剩余次数"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="red"
                    @click="delMode(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 20px; justify-content: flex-end" class="df">
            <el-pagination
              background
              :page-size="chipModeQuery.limit"
              layout="total,prev, pager, next"
              :total="chipModeTotal"
              :current-page="chipModeQuery.page"
              @current-change="chipModePageChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/util/extra-api";
import axios from "../../../util/api";

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        keyword: "",
        company_name: "",
        companyId: null,
      },
      list: [],
      total: 0,
      dailogVisible: false,
      modeDailogVisible: false,
      form: {
        companyId: "",
        company_name: "",
        companyName: "",
        chipId: "",
        chipName: "",
      },
      modeQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        chipId: null,
      },
      chipModeList: [],
      chipModeTotal: 0,
      chipModeQuery: {
        chipId: null,
        page: 1,
        limit: 10,
      },
      modeList: [],
      modeTotal: 0,
      editId: null,
      title: "新增",
      rules: {
        num: [{ required: true, message: "请输入次数", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    delMode(row) {
      console.log(row);
      api
        .post(`/v1/pc/device/deleteChipMode`, {
          chipId: row.chipId,
          modeId: row.modeId,
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.chipModeQuery.page > 1 && this.chipModeList.length) {
              this.chipModeQuery.page--;
            }
            this.getModeList();
            this.getChipModeList();
          }
        });
    },
    edit(row) {
      this.editId = row.id;
      this.form = {
        companyId: row.companyId,
        company_name: row.companyName,
        companyName: row.companyName,
        chipId: row.chipId,
        chipName: row.chipName,
      };
      this.title = "修改";
      this.dailogVisible = true;
    },
    addMode(row, index) {
      // console.log({
      //     chipId: this.modeQuery.chipId,
      //     modeId: row.id,
      //     num: row.num
      // });
      this.$refs["form" + index].validate((valid) => {
        if (valid) {
          api
            .post(`/v1/pc/device/modeAllot`, {
              chipId: this.modeQuery.chipId,
              modeId: row.id,
              num: row.num,
            })
            .then((res) => {
              if (res.code === 200) {
                if (this.modeList.length === 1 && this.modeQuery.page !== 1) {
                  this.modeQuery.page--;
                }
                this.getModeList();
                this.getChipModeList();
              }
            });
        } else {
          return false;
        }
      });
    },
    getChipModeList() {
      api.get(`/v1/pc/device/chipModeList`, this.chipModeQuery).then((res) => {
        if (res.code === 200) {
          this.chipModeList = res.data;
          this.chipModeTotal = res.total;
        }
      });
    },
    openMode(row) {
      this.modeQuery.chipId = row.id;
      this.modeQuery.page = 1;
      this.modeQuery.keyword = "";
      this.getModeList();
      this.chipModeQuery.chipId = row.id;
      this.chipModeQuery.page = 1;
      this.getChipModeList();
      this.modeDailogVisible = true;
    },
    getModeList() {
      api.get(`/v1/pc/device/modeList`, this.modeQuery).then((res) => {
        if (res.code === 200) {
          this.modeList = res.data.map((v) => {
            return {
              ...v,
              num: "",
            };
          });
          this.modeTotal = res.total;
        }
      });
    },
    del(row) {
      api.post("/v1/pc/device/deleteChip", { id: row.id }).then((res) => {
        if (res.code == 200) {
          if (this.list.length == 1 && this.query.page > 1) {
            this.query.page--;
          }
          this.getList();
        }
      });
    },
    pageChange(page) {
      this.query.page = page;
      this.getList();
    },
    modePageChange(page) {
      this.modeQuery.page = page;
      this.getModeList();
    },
    chipModePageChange(page) {
      this.chipModeQuery.page = page;
      this.getChipModeList();
    },
    addChip() {
      if (this.title === "新增") {
        api.post(`/v1/pc/device/addChip`, this.form).then((res) => {
          this.dailogVisible = false;
          this.getList();
          this.form = {
            companyId: "",
            company_name: "",
            companyName: "",
            chipId: "",
            chipName: "",
          };
        });
      } else {
        api
          .post(`/v1/pc/device/updateChip`, {
            id: this.editId,
            ...this.form,
          })
          .then((res) => {
            this.dailogVisible = false;
            this.getList();
            this.form = {
              companyId: "",
              company_name: "",
              companyName: "",
              chipId: "",
              chipName: "",
            };
          });
      }
    },
    getList() {
      api.get(`/v1/pc/device/chipList`).then((res) => {
        if (res.code === 200) {
          this.list = res.data;
          this.total = res.total;
        }
      });
    },
    onSubmit() {
      this.query.page = 1;
      this.getList();
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios.get("/pc/company/signList/2", {company_name:this.form.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    querySearchAsync1(queryString, cb) {
      if (queryString) {
        axios.get("/pc/company/signList/2", {company_name:this.query.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(item) {
      this.form.companyId = item.company_id;
      this.form.company_name = item.company_name;
      this.form.companyName = item.company_name;
    },

    handleSelect1(item) {
      this.query.companyId = item.company_id;
      this.form.company_name = item.company_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: #f56c6c !important;
}
.scroll-box::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 0;
}

.scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease;
  cursor: pointer;
}
</style>